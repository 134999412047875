import { useEffect, useMemo, useState } from 'react';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import {
  Ampli,
  ampli as analyticsClient,
  DefaultConfiguration,
  ensureGetUserId,
} from '@/utils/amplitude';
import { uuid } from '@/helpers/uuid';
import { usePathname } from 'next/navigation';

export interface AmplitudeClients {
  experiments: ExperimentClient;
  analytics: Ampli;
}

export function useAmplitude() {
  const pathname = usePathname();
  const experimentsClient = useMemo(
    () =>
      Experiment.initializeWithAmplitudeAnalytics(
        String(process.env.AMPLITUDE_EXPERIMENTS_API_KEY),
        {},
      ),
    [],
  );
  const [, setNavigationHistory] = useState<string[]>([pathname || '/']);

  useEffect(() => {
    analyticsClient.load({
      client: {
        apiKey: String(process.env.AMPLITUDE_ANALYTICS_API_KEY),
        configuration: {
          ...DefaultConfiguration,
          logLevel: 3,
          userId: ensureGetUserId(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!sessionStorage?.getItem('user-sync-id')) {
      sessionStorage?.setItem('user-sync-id', uuid());
    }
  }, []);

  useEffect(() => {
    const onChangeRoute = () => {
      setNavigationHistory((previousHistory: string[]) => {
        sessionStorage?.setItem(
          'amplitude-source',
          window.location.host + previousHistory[previousHistory.length - 1],
        );

        return [...previousHistory, pathname || '/'];
      });
    };

    window.addEventListener('popstate', onChangeRoute);

    return () => {
      window.removeEventListener('popstate', onChangeRoute);
    };
  }, [pathname]);

  return {
    experiments: experimentsClient,
    analytics: analyticsClient,
  };
}
