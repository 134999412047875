'use client';

import dynamic from 'next/dynamic';
import { useAmplitude } from '../hooks/useAmplitude';
const Providers = dynamic(() => import('./providers'));
// Use dynamic to import Providers because load amplitude first is needed, after we can load providers scripts...

interface Props {
  children: React.ReactNode;
}
export function PageProviders({ children }: Props) {
  const amplitudeClients = useAmplitude();

  return <Providers amplitudeClients={amplitudeClients}>{children}</Providers>;
}
