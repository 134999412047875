import { useEffect } from 'react';
import { isProduction } from '@/constants/checkEnvironment';
import { GApageView } from '@/utils/Analytics'; // TODO(@gabrieldissotti) verificar se é necessário uma nova implementação

export function usePageView() {
  useEffect(() => {
    if (isProduction) {
      GApageView(window.location.pathname); // TODO(@gabrieldissotti) talvez uma config via cms
    }
  }, []);
}
