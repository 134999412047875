import { useEffect } from 'react';

export function usePersistQuery() {
  useEffect(() => {
    const [, query] = window.location.search.split('?');

    const queryData = [];

    if (query) {
      const fields = query.split('&');
      for (let i = 0; i < fields.length; i += 1) {
        const [key, value] = fields[i].split('=');
        if (
          key.indexOf('utm_') === 0 ||
          key.indexOf('gclid') === 0 ||
          key.indexOf('fbclid') === 0 ||
          key.indexOf('defaultChannel') === 0 ||
          key.indexOf('af_') === 0 ||
          key.indexOf('c') === 0
        ) {
          queryData.push({ key, value });
        }
      }
    }

    if (queryData.length) {
      sessionStorage.setItem('queryData', JSON.stringify(queryData));
    }
  }, []);
}
