'use client';

import { usePageView } from './hooks/usePageView';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { GTM_SETTINGS } from '@/constants/analyticsSettings';
import { GAinitialize } from '@/utils/Analytics';
import { usePersistQuery } from './hooks/usePersistQuery';
import { useAttachUTMOnLinks } from './hooks/useAttachUTMOnLinks';

export function SiteScripts() {
  usePageView();
  usePersistQuery();
  useAttachUTMOnLinks();

  useEffect(() => {
    TagManager.initialize(GTM_SETTINGS);

    (() => GAinitialize())();
  }, []);

  return null;
}
